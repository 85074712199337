import { Injectable } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/angular';
import { ProcessErrorCode } from 'src/app/ajs-upgraded-providers';
import { DisplayApiService } from 'src/app/api/services/display-api.service';
import { ScheduleApiService } from 'src/app/api/services/schedule-api.service';
import { UserStateService } from 'src/app/auth/services/user-state.service';
import { TrackerService } from 'src/app/components/logging/tracker.service';
import { ModalService } from 'src/app/components/modals/modal.service';
import { CurrentPlanService } from 'src/app/components/plans/current-plan.service';
import { FeaturesService } from 'src/app/components/plans/features.service';
import { ScheduleService } from 'src/app/schedules/services/schedule.service';
import { type Display } from './display';
import { DisplayActivationService } from './display-activation.service';
import { PlayerLicenseService } from './player-license.service';

@Injectable({
  providedIn: 'root'
})
export class DisplayService {

  static readonly playerNamesWithHiddenProductNames = [
    'Android Player'
  ];

  loadingDisplay: boolean;
  savingDisplay: boolean;
  errorMessage: string;
  apiError: string;
  display: Display;
  scheduleTypeName: string;
  companyId: string;

  constructor(
    private trackerService: TrackerService,
    private featuresService: FeaturesService,
    private playerLicenseService: PlayerLicenseService,
    private displayApiService: DisplayApiService,
    private scheduleApiService: ScheduleApiService,
    private stateService: StateService,
    private uiRouterGlobals: UIRouterGlobals,
    private scheduleService: ScheduleService,
    private processErrorCode: ProcessErrorCode,
    private userStateService: UserStateService,
    private currentPlanService: CurrentPlanService,
    private modalService: ModalService,
    // Initialize service to handle events
    // Should use APP_INITIALIZER but it requires AJS dependencies so it fails
    displayActivationService: DisplayActivationService
  ) {
    this.init();
  }

  private _clearMessages () {
    this.loadingDisplay = false;
    this.savingDisplay = false;

    this.apiError = '';
  }

  init () {
    this.display = {
      'width': 1920,
      'height': 1080,
      'status': 1,
      'restartEnabled': true,
      'restartTime': '02:00',
      'autostartOnBoot': true,
      'monitoringEnabled': false,
      'useCompanyAddress': true,
      'playerProAssigned': false,
      'playerProAuthorized': false,
      'isDisplayControlEnabled': false
    };

    this._clearMessages();
  }

  newDisplay () {
    this.trackerService.displayEvent('Add Display');

    this.init();

    if (this.featuresService.isFeatureAvailable('monitoring')) {
      this.display.monitoringEnabled = true;
    }

    if (this.playerLicenseService.isProAvailable(this.display)) {
      this.display.playerProAssigned = true;
      this.display.playerProAuthorized = true;
    }
  }

  getDisplay (displayId): Promise<void> {
    this._clearMessages();

    //show loading spinner
    this.loadingDisplay = true;

    return this.displayApiService.get(displayId)
      .then((result) => {
        this.display = result.item;
        this.display.originalPlayerProAuthorized = this.display.playerProAuthorized;

        this.display.overrideSchedules = [];
        if (this.hasOverrideSchedule(this.display)) {

          for (let i = 0; i < this.display.overrideScheduleIds.length; i++) {
            this.display.overrideSchedules.push({
              id: this.display.overrideScheduleIds[i],
              name: this.display.overrideScheduleNames[i],
              scheduleTypeName: ''
            });

            this.scheduleApiService.get(this.display.overrideScheduleIds[i]).then((result) => {
              if (result.item) {
                this.display.overrideSchedules[i].scheduleTypeName = result.item.scheduleTypeName;
                this.display.overrideSchedules[i].companyId = result.item.companyId;
              }
            });
          }
        }

        return Promise.resolve();
      })
      .then(null, (e) => {
        this._showErrorMessage('get', e);

        return Promise.reject();
      })
      .finally(() => {
        this.loadingDisplay = false;
      });
  }

  addDisplay (selectedSchedule) {
    this._clearMessages();

    //show loading spinner
    this.loadingDisplay = true;
    this.savingDisplay = true;

    return this.displayApiService.add(this.display)
      .then((resp) => {
        if (resp && resp.item && resp.item.id) {
          if (this.display.playerProAuthorized) {
            this.playerLicenseService.toggleDisplayLicenseLocal(true);
          }

          if (this.display.activationKey) {
            this.trackerService.displayEvent('Display Activated', resp.item.id, resp.item.name);
          } else {
            this.trackerService.displayEvent('Display Created', resp.item.id, resp.item.name);
          }

          if (this.display.displayControlUpdated) {
            this.displayApiService.uploadControlFile(resp.item.id, this.display.displayControl);
          }

          return this.scheduleService.addToDistribution(resp.item, selectedSchedule)
            .then(() => {
              if (this.uiRouterGlobals.current.name === 'apps.displays.add' || this.uiRouterGlobals.current.name === 'apps.displays.activate') {
                this.stateService.go('apps.displays.details', {
                  displayId: resp.item.id,
                  activated: !!this.display.activationKey
                });
              }

              return Promise.resolve();
            })
            .catch(() => {
              this.apiError = this.scheduleService.apiError;
            });
        } else {
          return Promise.reject();
        }
      }, (e) => {
        this._showErrorMessage('add', e);
        return Promise.reject();
      })
      .finally(() => {
        this.loadingDisplay = false;
        this.savingDisplay = false;
      });
  }

  updateDisplay (selectedSchedule) {
    this._clearMessages();

    //show loading spinner
    this.loadingDisplay = true;
    this.savingDisplay = true;

    return this._update(this.display.id, this.display)
      .then(() => { return this._updateLicenseIfNeeded(); })
      .then(() => {
        if (this.display.displayControlUpdated) {
          this.displayApiService.uploadControlFile(this.display.id, this.display.displayControl);
        }

        return this.scheduleService.addToDistribution(this.display, selectedSchedule)
          .then(() => {
            return Promise.resolve();
          })
          .catch(() => {
            this.apiError = this.scheduleService.apiError;
          });
      })
      .catch((e) => {
        this._showErrorMessage('update', e);
        return Promise.reject();
      })
      .finally(() => {
        this.loadingDisplay = false;
        this.savingDisplay = false;
      });
  }

  private _update (displayId, fields) {
    return this.displayApiService.update(displayId, fields).then((result) => {
      this.trackerService.displayEvent('Display Updated', displayId, fields && fields.name);
      return result;
    });
  }

  private _updateLicenseIfNeeded (): Promise<void> {
    if (this.display.playerProAuthorized !== this.display.originalPlayerProAuthorized) {
      return this.playerLicenseService.updateDisplayLicense(this.display)
        .then(() => {
          this.display.originalPlayerProAuthorized = this.display.playerProAuthorized;
        })
        .catch((err) => {
          this.apiError = this.playerLicenseService.apiError;
          return Promise.reject(err);
        });
    } else {
      return Promise.resolve();
    }
  }

  applyFields (display, fields) {
    return this._update(display.id, fields).then((result) => {
      Object.assign(display, fields);
      return result;
    });
  }

  deleteDisplayByObject (displayObject) {
    return this.displayApiService.delete(displayObject.id)
      .then(() => {
        this.trackerService.displayEvent('Display Deleted', displayObject.id, displayObject.name);

        if (displayObject.playerProAssigned) {
          this.playerLicenseService.toggleDisplayLicenseLocal(false);
        }
      });
  }

  deleteDisplay () {
    this._clearMessages();

    //show loading spinner
    this.loadingDisplay = true;

    this.deleteDisplayByObject(this.display)
      .then(() => {
        this.display = {};

        this.stateService.go('apps.displays.list');
      })
      .then(null, (e) => {
        this._showErrorMessage('delete', e);
      })
      .finally(() => {
        this.loadingDisplay = false;
      });
  }

  private _showErrorMessage (action, e) {
    this.errorMessage = 'Failed to ' + action + ' display.';
    this.apiError = this.processErrorCode('Display', action, e);

    console.error(this.apiError, e);
  }

  showLicenseRequired (display?: Display): boolean {
    display = display || this.display;

    return display && !display.playerProAuthorized && !this.userStateService.isRiseAdmin();
  }

  showProductName () {
    const display = this.display;

    return display && display.productName &&
      (!DisplayService.playerNamesWithHiddenProductNames.includes(display.playerName) || this.userStateService.isRiseAdmin());
  }

  hasSchedule (display: Display): boolean {
    return display && display.scheduleId && display.scheduleId !== 'DEMO';
  }

  hasOverrideSchedule (display: Display): boolean {
    return display && display.overrideScheduleIds && display.overrideScheduleIds.length > 0;
  }

  toggleProAuthorized () {
    if (!this.playerLicenseService.isProAvailable(this.display) &&
        !this.display.originalPlayerProAuthorized) {
      this.display.playerProAuthorized = false;
      this.currentPlanService.confirmAndPurchase();
    } else {
      this.playerLicenseService.updateDisplayLicenseLocal(this.display);
    }
  }

  toggleScreenSharing () {
    this.display.screenSharingStandard = !this.display.screenSharingMode
      || (this.display.screenSharingEnabled && this.display.screenSharingMode !== 'moderated') ;
  }

  confirmAssignLicense(selectedSchedule) {
    if (this.scheduleService.requiresLicense(selectedSchedule) &&
        !this.display.playerProAuthorized) {
      this.modalService.confirm('Assign license?',
          'You\'ve selected a schedule that contains presentations. In order to show this schedule on this display, you need to license it. Assign license now?',
          'Yes', 'No')
        .then(() => {
          // Toggle license as if they clicked the checkbox
          this.display.playerProAuthorized = true;

          this.toggleProAuthorized();
        });
    }
  }
}
